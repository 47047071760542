
import WinesContent from '~/bbrTheme/modules/catalog/product/components/product-types/content/WinesContent.vue';
import { PropType } from '@nuxtjs/composition-api';

export default {
  name: 'SpiritsContent',
  mixins: [WinesContent],
  props: {
    aboutThisTitle: {
      type: String,
      default: 'About this spirit',
    },
    aboutThisAttributes: {
      type: Array as PropType<string[]>,
      default: () => ['property', 'region', 'main_ingredients']
    }
  },
};
